import React from "react";
import { Drawer } from "vaul";

export default function BaseBottomSheet({
  // trigger,
  title,
  content,
  scrollable = true,
  isOpen,
  setIsOpen,
}) {
  return (
    <Drawer.Root open={isOpen} onOpenChange={setIsOpen}>
      {/*<Drawer.Trigger className="focus:outline-none" asChild>*/}
      {/*  {trigger}*/}
      {/*</Drawer.Trigger>*/}
      <Drawer.Portal>
        <Drawer.Overlay className="fixed z-20 inset-0 bg-black/50" />
        <Drawer.Content
          aria-describedby={undefined}
          className={`fixed bottom-0 z-50 left-0 right-0 flex ${
            scrollable ? "max-h-[75%]" : "max-h-[95%]"
          }  flex-col rounded-t-2xl bg-white dark:bg-gray-900 dark:text-gray-200 focus:outline-none`}
        >
          <div className="mx-auto mb-4 -mt-4 h-1.5 w-12 flex-shrink-0 rounded-full bg-zinc-300 dark:bg-zinc-500" />
          <Drawer.Title>{title}</Drawer.Title>
          <div
            className={`mx-auto mb-2 flex w-full flex-col standalone:mb-8 ${
              scrollable ? "overflow-y-auto" : ""
            }`}
          >
            {content}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
