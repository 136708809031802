import React, { useState } from "react";

import { Combobox } from "@headlessui/react";
import { IconMagnifyingGlass } from "../../../global/components/icons/IconMagnifyingGlass";

export default function PlayerSearch({ players, hiddenFieldName }) {
  const hideSelected = false;
  const takenIds = [];
  const selectedPlayerId = null;

  const [query, setQuery] = useState("");
  const filteredHidden = hideSelected
    ? players.filter((player) => {
        return player.id === selectedPlayerId || !takenIds.includes(player.id);
      })
    : [];
  const filteredPeople =
    query.length < 1
      ? filteredHidden.slice(0, 10)
      : players
          .filter((player) => {
            return (
              player.name.toLowerCase().includes(query.toLowerCase()) &&
              !takenIds.includes(player.id)
            );
          })
          .slice(0, 20);

  function selectPlayer(player) {
    if (player) {
      console.log("setting player to", player);
      setQuery("");

      // Update the hidden input field in the Symfony form
      const hiddenInput = document.querySelector(
        `input[name="${hiddenFieldName}"]`,
      );

      if (hiddenInput) {
        hiddenInput.value = player.id;
      }
    }
  }

  return (
    <Combobox onChange={selectPlayer}>
      <div className="relative">
        {IconMagnifyingGlass}
        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) => person?.name}
          className="h-12 w-full border-0 bg-transparent pl-11 pr-9 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-200 sm:text-md"
          placeholder={`Search...`}
          autoComplete="off"
          autoCorrect="off"
        />
      </div>

      {filteredPeople.length > 0 && (
        <Combobox.Options
          // static={true}
          className={`z-20 scroll-py-2 overflow-y-auto border-0 border-t border-gray-200 dark:border-gray-600 py-2 text-md text-gray-800 dark:bg-gray-800`}
        >
          {filteredPeople.map((player) => (
            /* Use the `active` state to conditionally style the active option. */
            /* Use the `selected` state to conditionally style the selected option. */

            <Combobox.Option
              disabled={false}
              className={false ? "opacity-50" : ""}
              key={player.id}
              value={player}
            >
              {({ focus, selected }) => (
                <div
                  className={`flex cursor-default select-none items-center justify-between px-4 py-2 font-medium leading-snug ${
                    focus ? "bg-gray-100 dark:bg-gray-700" : ""
                  }`}
                  id="headlessui-combobox-option-5"
                  role="option"
                  tabIndex="-1"
                  aria-selected="false"
                  data-headlessui-state=""
                >
                  <div className="my-1 flex items-center justify-center dark:text-white">
                    <div className="mr-3 h-7 w-7">
                      <img
                        className="h-7 w-7 rounded-full"
                        src={`/build/images/sport/teams/logos/512/${player.team.id}.png`}
                      />
                    </div>
                    <div className="flex-grow text-md">
                      <span className="inline-flex whitespace-nowrap items-center justify-center">
                        {player.firstName} {player.lastName}{" "}
                        {/*<span className="ml-1 text-slate-700 font-semibold leading-none text-xxs rounded-md bg-slate-200 mt-0.5 px-1 py-1">*/}
                        {/*  {parseFloat(avgPoints[player.id] || 0).toFixed(1)}*/}
                        {/*</span>*/}
                      </span>
                      {/*<div className="-mt-0.5 text-xs font-normal leading-snug text-sky-950 dark:text-gray-400">*/}
                      {/*  <OpponentString player={player} />*/}
                      {/*</div>*/}
                    </div>
                  </div>
                  {/*<div className="flex flex-col items-end justify-center sm:pr-3">*/}
                  {/*  {contest.stats.totalCount > 0 && (*/}
                  {/*    <div className="text-md font-normal text-right text-sky-950">*/}
                  {/*      {(*/}
                  {/*        ((contest.stats.selections[player.id] || 0) /*/}
                  {/*          contest.stats.totalCount) **/}
                  {/*        100*/}
                  {/*      ).toFixed(1)}*/}
                  {/*      % selected*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                  {/*  {player.injuryStatus > 0 && (*/}
                  {/*    <span className="pl-1 pr-2 py-px bg-red-500 text-xxxs font-light text-white rounded-md inline-flex items-center justify-center">*/}
                  {/*      <IconInjury className="h-2.5 w-2.5 mr-1" /> Injured*/}
                  {/*    </span>*/}
                  {/*  )}*/}
                  {/*</div>*/}
                </div>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </Combobox>
  );
}
