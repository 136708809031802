import { registerReactControllerComponents } from "@symfony/ux-react";
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
// any CSS you import will output into a single css file (app.css in this case)
import "./styles/app.css";

// start the Stimulus application
import "./bootstrap";

// enable the interactive UI components from Flowbite
import { initFlowbite } from "flowbite";

// Registers React controller components to allow loading them from Twig
//
// React controller components are components that are meant to be rendered
// from Twig. These component then rely on other components that won't be called
// directly from Twig.
//
// By putting only controller components in `react/controllers`, you ensure that
// internal components won't be automatically included in your JS built file if
// they are not necessary.
registerReactControllerComponents(
  require.context("./react/global/controllers", true, /\.(j|t)sx?$/),
);

document.addEventListener("turbo:render", () => {
  initFlowbite();
});
document.addEventListener("turbo:frame-render", () => {
  initFlowbite();
});

document.addEventListener("turbo:submit-end", (event) => {
  if (event.detail.fetchResponse.response.redirected === true) {
    // If submit was successful, keep form buttons in disabled state
    event.target.querySelectorAll("[type=submit]").forEach((button) => {
      button.disabled = true;
      if (button.dataset.turboSubmitsWith) {
        button.textContent = button.dataset.turboSubmitsWith;
      }
    });
  }
});
